@import 'styles/bootstrap-core.scss';

.drawCardImage {
  height: 150px;

  @include media-breakpoint-down(xl) {
    height: 200px;
  }

  @include media-breakpoint-down(lg) {
    height: 150px;
  }

  @include media-breakpoint-down(md) {
    height: 400px;
  }

  @include media-breakpoint-down(sm) {
    height: 250px;
  }
}
