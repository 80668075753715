@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../styles/variables';

.homeDrawSlider {
  min-height: 665px;
  height: calc(100vh - 70px);
  max-height: 800px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-bottom: 40px;

  .slide {
    @media screen and (min-width: 1600px) {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      overflow: hidden;
    }
  }

  :global {
    .slick-list {
      min-width: 100%;
      display: flex;

      .slick-slide > div > div {
        display: flex !important;
        flex-direction: column;
      }
    }

    .slick-next,
    .slick-prev {
      margin-top: -90px;
    }
  }
}
